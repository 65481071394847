<template>
    <!--       의뢰 아이템 리스트 레이아웃-->
    <div class="mypage_curation_send_wrap">
        <table class="my_history_table" width="100%"  border="0" cellspacing="0" cellpadding="0"
               style = "table-layout: auto; width: 1200px; table-layout: fixed;">
            <colgroup>
                <col width="95px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="140px" />
                <col width="" />
                <col width="140px" />
<!--                <col width="95px" />
                <col width="143px" />
                <col width="255px" />
                <col width="195px" />
                <col width="160px" />
                <col width="165px" />
                <col width="155px" />-->
            </colgroup>
            <tr class="title_row">
                <th colspan="1">No</th>
                <th colspan="1">{{$t('image')}}</th>
                <th colspan="1">{{$t('subject')}}</th>
                <th colspan="1">{{$t('curator')}}</th>
                <th colspan="1">{{$t('status')}}</th>
                <th colspan="1">{{ $t('selling_price')}}<br/>({{$t('max_bid')}})</th>
                <th colspan="1">{{$t('bid_history')}}</th>
                <th colspan="1">{{$t('r_date')}}</th>
                <th colspan="1">{{$t('option')}}</th>
<!--                <th colspan="1">{{$t('cancel_request')}}</th>-->
            </tr>
            <tr v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
                <td class="cursor" @click="moveItemDetail(curation)">{{ tableIdx(index) }}</td>  <!-- No -->
                <td class="cursor" @click="moveItemDetail(curation)"><!-- 이미지 -->
                    <img :src="returnItemImage(curation)" width="78" height="49"/>
                </td>
                <td class="cursor t_subject" @click="moveItemDetail(curation)">{{ curation.i_name }}</td>  <!-- 아이템명 -->

                <td class="cursor" @click="moveItemDetail(curation)">{{ curation.curator_nick }}</td>  <!-- 큐레이터명 -->

                <td class="cursor" @click="moveItemDetail(curation)">{{ $t(returnStatusData(curation.ibc_status).showName) }}</td> <!-- 상태 -->

                <td class="cursor" @click="moveItemDetail(curation)">{{returnPrice(curation)}}</td> <!-- 가격 -->
                <td><!-- 경매내역 -->
                    <button @click="openAuctionHistory(curation.m_idx)"  v-if="curation.m_type === 1">
                        {{$t('bid_history')}}
                    </button>
                </td>
                <td class="cursor" @click="moveItemDetail(curation)" v-html="returnDateFormat(curation.created_at, 'YYYY-MM-DD<br>HH:mm')"></td><!-- 의뢰일시 -->
                <td><!-- 옵션 -->
                    <button class="endDate" @click="cancelRequest(curation)" v-if="hasCancel(curation)">{{$t('cancel_request')}}</button>
                    <button class="endDate" v-else-if="returnStatusData(curation.ibc_status).showMarketHistory" @click="$router.push(`/mypage/item/${curation.m_idx}/history`)">
                        {{$t('history_title')}}
                    </button>
                    <span v-else>{{ returnDetailStatus(curation) }}</span>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CurationListRequestLayout",
    mixins: [alertMixins, imageOption, dateMixins,imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, this.returnExtension(curation.ItemFile.Item[0].org_url))}`;
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false, showMarketHistory: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        hasCancel(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.hasCancel) {
                return true;
            }
            return curation.ibc_status === 1 && curation.m_type == null;
        },
        returnPrice(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if((status.value == 4 || curation.i_status === 'pricetag' || curation.i_status === 'auction') && !isNaN(curation.i_price)) {
                return util.Number.numFormat(curation.i_price)
            }
            return '-'
        },
        openAuctionHistory(m_idx) {
            this.$modal.show('auction-history-modal', {m_idx : m_idx})
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_sku}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_sku}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_sku}`
            }
            this.$router.push(url);
        },
        cancelRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    // this.errorAlert('추후에 기능이 추가됩니다.')
                    if(curation.ibc_status === 0) {
                        EventBus.$emit('curationListCancel', curation)
                    }else if(curation.ibc_status === 1){
                        EventBus.$emit('curationListDelete', curation.ibc_idx)
                    }

                },
                cancel: () => {

                }
            })
        },
        returnDetailStatus(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.value === 1 && curation.m_type == 1) {
                return this.$t('auction')
            }else if(status.value === 1 && curation.m_type == 3) {
                return this.$t('fix_selling')
            }
            return status.detailName === '-' ? '-' : this.$t(status.detailName)
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
