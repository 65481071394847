//0:대기,1:수락,2:거절,3:의뢰취소,4:판매완료,5:의뢰포기
export const curationStatus = [
    {
        value: 0,
        name: 'cr_st_ready',
        showName: 'waiting_for_acceptance',
        detailName: '-',
        description: '대기',
        hasCancel: true,
        showSendFilter: false,
        showReceiveFilter: false,
        showMarketHistory: false,
    },{
        value: 1,
        name: 'accept',
        showName: 'in_process2',
        description: '수락',
        detailName: '-',
        hasCancel: false,
        showSendFilter: true,
        showReceiveFilter: true,
        showMarketHistory: false,
    },{
        value: 2,
        name: 'refuse',
        showName: 'cancel',
        description: '거절',
        detailName: 'request_refuse',
        hasCancel: false,
        showSendFilter: true,
        showReceiveFilter: true,
        showMarketHistory: false,
    },{
        value: 3,
        name: 'cancel',
        showName: 'cancel',
        detailName: 'client_cancel',
        description: '의뢰취소 - 신청자',
        hasCancel: false,
        showSendFilter: true,
        showReceiveFilter: false,
        showMarketHistory: false,
    },{
        value: 4,
        name: 'sell_completed',
        showName: 'sell_completed',
        detailName: '-',
        description: '판매완료',
        hasCancel: false,
        showSendFilter: false,
        showReceiveFilter: false,
        showMarketHistory: true,
    },{
        value: 5,
        name: 'cancel',
        showName: 'cancel',
        detailName: 'client_cancel',
        description: '의뢰취소 - 의뢰인',
        hasCancel: false,
        showSendFilter: false,
        showReceiveFilter: false,
        showMarketHistory: false,
    },{
        value: 6,
        name: 'cancel',
        showName: 'cancel',
        detailName: 'curator_cancel',
        description: '의뢰취소 - 큐레이터',
        hasCancel: false,
        showSendFilter: false,
        showReceiveFilter: false,
        showMarketHistory: false,
    },{
        value: 7,
        name: 'cancel',
        showName: 'cancel',
        detailName: 'admin_delete',
        description: '의뢰취소 - 관리자 삭제',
        hasCancel: false,
        showSendFilter: false,
        showReceiveFilter: false,
        showMarketHistory: false,
    },
];
